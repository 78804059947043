import React from 'react';
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Blog from 'components/Software/Blog';
import ChatBanner from 'components/Software/ChatBanner';
import Footer from 'components/Saas/Footer';
import { Link } from 'gatsby';



const SavingsAccount = () => {
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden " data-scroll-index="0">
      <div className="container">
        <div className="content section-padding container">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Save Smart, Spend Wisely. Take Control of Your <span> Finances</span></h1>
                <p className="p">Save smart with our high-interest Savings Accounts designed to help you achieve your financial goals. Whether you're saving for the future, an emergency fund, or a major purchase.</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">       
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" /> 
        <img src="/assets/img/about/SAVINGS.png" alt=""  />        
      </div>
    </header>
      <main>
      <section className="about section-padding style-3" data-scroll-index="3">
      
        <div className="container">
          <div className="row gx-0 justify-content-end">
            <div className="col-lg-6">
            <div className="img  ">
          <img src="/assets/img/about/SAVINGS.png" alt="" className='img-body ' />          
        </div>
            </div>
            <div className="col-lg-6">
              <div className="" >
                <div className="section-head long-shape mb-40 style-3">
                  <h3>Savings <span>Account</span></h3>
                </div>
                <p className="p">
                Mutual Trust MFBank provides flexible saving options, competitive interest rates, and easy access through online banking. Start building your savings today and watch your money grow securely.
                </p>
                <br />
                <div className="text mb-20">
                  <h5>Requirements: </h5>
                  <ul>
                    <li>BVN</li>
                    <li>NIN</li>
                    <li>Passport</li>
                    <li>Residence Details</li>
                    <li>Utility Bill</li>
                    
                  </ul>
                </div>
                
                <a href="https://mtloans.ng/login?r=DM1" className="btn rounded-pill bg-blue2 sm-butn text-white">
                  <span>Open A savings account</span>
                </a>
              </div>
            </div>
          </div>
        </div>
    
    </section>
       
        <Blog />
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title> Open A Savings Account and Enjoy Yearly Interests </title>
<meta name="description" content="Reach your financial goals with our yearly interest savings account designed to help you save smart and easy." /> 

      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default SavingsAccount;